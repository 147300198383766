import React, { useState } from "react";
import axios from "axios";
import {
    FormControl,
    Input,
    Flex,
    Box,
    VStack,
    InputRightElement,
    InputGroup,
    useToast,
    Text,
    Button,
    Heading,
    FormErrorMessage,
    useBreakpointValue,
} from '@chakra-ui/react'
import { ReactComponent as EyeOutlineSVG } from '../../../assets/login1.2_img/eye_outline.svg';
import { ReactComponent as EyeSlashSVG } from '../../../assets/login1.2_img/eye_slash.svg';
import AuthService from "../../../services/auth.service";
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

export const SetNewPassword = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [confirmPassShow, setConfirmPassShow] = useState(false);
    const [newPassErr, setNewPassErr] = useState(false);
    const [confirmPassErr, setConfirmPassErr] = useState(false);
    const [newPassError, setNewPassError] = useState('');
    const [confirmPassError, setConfirmPassError] = useState('');
    const [isPasswordSaved, setIsPasswordSaved] = useState(false);
    const toast = useToast();

    const padding = useBreakpointValue({ base: 4, sm: 6, md: 8, lg: 10 });
    const containerWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "80%", lg: "70%" });
    const gapHeight = useBreakpointValue({ base: "15px", sm: "100px" });

    const handleClickShow = () => setShow(!show);
    const handleClickConfirmShow = () => setConfirmPassShow(!confirmPassShow);

    const validatePasswords = () => {
        let isValid = true;
        if (pass.length < 8) {
            setNewPassErr(true);
            setNewPassError("Password must be at least 8 characters long");
            isValid = false;
        } else {
            setNewPassErr(false);
            setNewPassError('');
        }

        if (pass !== confirmPass) {
            setConfirmPassErr(true);
            setConfirmPassError("Passwords do not match");
            isValid = false;
        } else {
            setConfirmPassErr(false);
            setConfirmPassError('');
        }

        return isValid;
    };

    const handleSave = async () => {
        if (!validatePasswords()) {
            return;
        }

        try {
            const response = await axios.post(API_URL + '/api/reset_password', {
                password: pass,
                token: props.resetToken,
            });

            if (response.status === 200) {
                console.log("Password reset successful");
                setIsPasswordSaved(true);
                // Handle login and redirect after a short delay
                setTimeout(() => {
                    AuthService.login(props.email, props.phone, pass).then(
                        () => {
                            console.log("Login successful, redirecting to /toefl/speaking");
                            window.location.href = '/toefl/speaking';
                        },
                        (error) => {
                            console.log("Login error:", error);
                            window.location.href = '/login';
                        }
                    );
                }, 3000);
            }
        } catch (error) {
            console.error("Password reset error:", error);
            toast({
                title: "Password reset failed",
                description: "An error occurred. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const inputStyles = {
        color: "#232323",
        fontWeight: 'medium',
        fontSize: '16px',
        _placeholder: { color: '#847E7C', fontWeight: 'medium', fontSize: '16px' },
        border: '2px solid #E4E6E6',
        height: "53px",
        _focus: { 
            borderColor: "#232323",
            color: "#232323"
        },
    };

    return (
        <Flex direction="column" h="100%" p={padding} justifyContent="center" alignItems="center">
            <VStack spacing={4} width={containerWidth}>
                <Box w="100%" shadow="md" borderWidth="1px" bg="white" borderRadius="8">
                    <VStack alignItems="stretch" spacing={6} p={padding}>
                        <Flex onClick={props.onBackToLogin} alignItems="center" cursor="pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.8572 6.81378H3.90239L6.52282 4.06273C6.63197 3.95206 6.71903 3.81967 6.77892 3.6733C6.83881 3.52692 6.87034 3.36949 6.87166 3.21019C6.87298 3.05088 6.84406 2.8929 6.7866 2.74545C6.72914 2.59801 6.64428 2.46405 6.53698 2.3514C6.42968 2.23875 6.30209 2.14967 6.16164 2.08934C6.0212 2.02902 5.87072 1.99866 5.71898 2.00005C5.56724 2.00143 5.41728 2.03453 5.27786 2.0974C5.13843 2.16028 5.01233 2.25168 4.90691 2.36627L0.335739 7.16531C0.229315 7.27676 0.144878 7.40916 0.0872666 7.55491C0.0296551 7.70067 0 7.85693 0 8.01474C0 8.17255 0.0296551 8.32881 0.0872666 8.47457C0.144878 8.62033 0.229315 8.75273 0.335739 8.86417L4.90691 13.6632C5.12244 13.8818 5.41111 14.0027 5.71075 14C6.01039 13.9972 6.29702 13.871 6.5089 13.6486C6.72078 13.4262 6.84097 13.1252 6.84357 12.8107C6.84618 12.4961 6.73099 12.193 6.52282 11.9668L3.90239 9.2133H14.8572C15.1603 9.2133 15.451 9.0869 15.6653 8.8619C15.8796 8.6369 16 8.33174 16 8.01354C16 7.69535 15.8796 7.39018 15.6653 7.16519C15.451 6.94019 15.1603 6.81378 14.8572 6.81378Z" fill="#232323"/>
                            </svg>
                            <Text ml={2} color="#232323" fontSize={14} fontWeight="500">
                                {t('setNewPassword.backToLogin')}
                            </Text>
                        </Flex>

                        <Box>
                            <Heading as="h2" fontSize="24px" fontWeight="bold" color="#232323" mb={1}>
                                {t('setNewPassword.title')}
                            </Heading>
                            <Text color="#717171" fontSize="16px" fontWeight="medium">
                                {t('setNewPassword.title')}
                            </Text>
                        </Box>

                        <VStack spacing={4} align="stretch">
                            <FormControl isInvalid={newPassErr}>
                                <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                                    {t('setNewPassword.newPassword')}
                                </Text>
                                <InputGroup>
                                    <Input
                                        type={show ? 'text' : 'password'}
                                        placeholder={t('setNewPassword.passwordPlaceholder')}
                                        value={pass}
                                        onChange={(e) => {
                                            setPass(e.target.value)
                                            setNewPassErr(false)
                                            setNewPassError('')
                                        }}
                                        onBlur={validatePasswords}
                                        {...inputStyles}
                                        pr='4.5rem'
                                        borderColor={newPassErr ? "#C81E1E" : "#E4E6E6"}
                                        _hover={ {bg: "#F7F7F7"} }
                                        bg={newPassErr ? "#FDF2F2" : "white"}
                                        _focus={{
                                            borderColor: newPassErr ? "#C81E1E" : "#232323",
                                            boxShadow: "none"
                                        }}
                                        sx={{
                                            '&[aria-invalid=true]': {
                                                borderColor: '#C81E1E',
                                                boxShadow: 'none',
                                                _focus: {
                                                    borderColor: '#C81E1E',
                                                    boxShadow: 'none',
                                                }
                                            }
                                        }}
                                    />
                                    <InputRightElement width='4.5rem' height="100%">
                                        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                            <Box 
                                                as="button" 
                                                onClick={handleClickShow}
                                                aria-label={show ? "Hide password" : "Show password"}
                                            >
                                                {show ? <EyeOutlineSVG /> : <EyeSlashSVG />}
                                            </Box>
                                        </Flex>
                                    </InputRightElement>
                                </InputGroup>
                                {newPassErr && (
                                    <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                        {t('setNewPassword.passwordTooShort')}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl isInvalid={confirmPassErr}>
                                <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                                    {t('setNewPassword.confirmPassword')}
                                </Text>
                                <InputGroup>
                                    <Input
                                        type={confirmPassShow ? 'text' : 'password'}
                                        placeholder={t('setNewPassword.confirmPasswordPlaceholder')}
                                        value={confirmPass}
                                        onChange={(e) => {
                                            setConfirmPass(e.target.value)
                                            setConfirmPassErr(false)
                                            setConfirmPassError('')
                                        }}
                                        onBlur={validatePasswords}
                                        {...inputStyles}
                                        pr='4.5rem'
                                        borderColor={confirmPassErr ? "#C81E1E" : "#E4E6E6"}
                                        _hover={ {bg: "#F7F7F7"} }
                                        bg={confirmPassErr ? "#FDF2F2" : "white"}
                                        _focus={{
                                            borderColor: confirmPassErr ? "#C81E1E" : "#232323",
                                            boxShadow: "none"
                                        }}
                                        sx={{
                                            '&[aria-invalid=true]': {
                                                borderColor: '#C81E1E',
                                                boxShadow: 'none',
                                                _focus: {
                                                    borderColor: '#C81E1E',
                                                    boxShadow: 'none',
                                                }
                                            }
                                        }}
                                    />
                                    <InputRightElement width='4.5rem' height="100%">
                                        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                            <Box 
                                                as="button" 
                                                onClick={handleClickConfirmShow}
                                                aria-label={confirmPassShow ? "Hide password" : "Show password"}
                                            >
                                                {confirmPassShow ? <EyeSlashSVG /> : <EyeOutlineSVG />}
                                            </Box>
                                        </Flex>
                                    </InputRightElement>
                                </InputGroup>
                                {confirmPassErr && (
                                    <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                        {t('setNewPassword.passwordMismatch')}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </VStack>

                        <Button
                            w={'100%'}
                            borderRadius={'8px'}
                            bg={'black'}
                            color="white"
                            height="53px"
                            _hover={{ bg: "#232323" }}
                            onClick={handleSave}
                        >
                            {t('setNewPassword.saveButton')}
                        </Button>

                        <Text
                            fontSize={16}
                            fontWeight={500}
                            color="#717171"
                            fontFamily="THICCCBOI, sans-serif"
                            textAlign="center"
                        >
                            {t('setNewPassword.helpText')}{' '}
                            <Text as="span" fontWeight={600} color="#232323">
                                support@lingoleap.ai
                            </Text>
                        </Text>
                    </VStack>
                </Box>

                <Box height={gapHeight} />

                {isPasswordSaved && (
                    <Flex width="100%" justifyContent="flex-end">
                        <Box
                            p={3}
                            bg='white'
                            borderRadius='md'
                            boxShadow='md'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            borderTop='4px solid #38A169'
                            width="343px"
                            height="61px"
                        >
                            <Flex alignItems='center' justifyContent='center'>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '12px'}}>
                                    <path d="M0.444336 10.5009C0.444336 5.22348 4.7225 0.945312 9.99989 0.945312C15.2773 0.945312 19.5554 5.22348 19.5554 10.5009C19.5554 15.7783 15.2773 20.0564 9.99989 20.0564C4.7225 20.0564 0.444336 15.7783 0.444336 10.5009Z" fill="#0E9F6E"/>
                                    <path d="M8.86216 13.8346C8.7316 13.8353 8.60601 13.7739 8.51227 13.6638L6.15098 10.8863C6.10391 10.8306 6.06636 10.7642 6.04046 10.6909C6.01456 10.6176 6.00083 10.5389 6.00004 10.4591C5.99844 10.2981 6.04968 10.1429 6.14248 10.0277C6.23528 9.91249 6.36203 9.84667 6.49486 9.84474C6.62769 9.84281 6.75571 9.90492 6.85077 10.0174L8.86416 12.3847L13.1489 7.34051C13.2441 7.22802 13.3722 7.16597 13.5051 7.16802C13.6381 7.17006 13.7649 7.23603 13.8577 7.35141C13.9505 7.46679 14.0016 7.62213 14 7.78326C13.9983 7.94439 13.9439 8.0981 13.8487 8.21059L9.21206 13.6638C9.11831 13.7739 8.99273 13.8353 8.86216 13.8346Z" fill="white"/>
                                </svg>
                                <Text color='black' fontWeight='medium' textAlign='center'>
                                    {t('setNewPassword.passwordSaved')}
                                </Text>
                            </Flex>
                        </Box>
                    </Flex>
                )}
            </VStack>
        </Flex>
    );
}