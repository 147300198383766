import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeOutlineSVG } from '../../../assets/login1.2_img/eye_outline.svg';
import { ReactComponent as EyeSlashSVG } from '../../../assets/login1.2_img/eye_slash.svg';
import axios from "axios";
import {
    FormControl,
    FormLabel,
    Select,
    Input,
    InputGroup,
    InputRightElement,
    Image,
    Box,
    Link,
    FormErrorMessage,
    Flex,
    VStack,
    Text,
} from '@chakra-ui/react'

import countriesEn from '../countries.json'
import countriesCn from '../countries_cn.json'
import { SignupZone } from "../SignUpZone/signupZone";
import debounce from "lodash/debounce";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

export const PhoneRegistry = (props) => {
    const { t, i18n } = useTranslation();
    const countries = i18n.language === 'cn' ? countriesCn : countriesEn;
    const [show, setShow] = useState(false)
    const [prefix, setPrefix] = useState(() => {
        const defaultCountry = countries.find(c => c.isoCode === 'US');
        return defaultCountry ? defaultCountry.dialCode : '+1';
    })
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [passErr, setPassErr] = useState(false)
    const [countryCodeErr, setCountryCodeErr] = useState(false)
    const [invitationCode, SetInvitationCode] = useState('')
    const [phoneErr, setPhoneErr] = useState(false)
    const [loginErr, setLoginErr] = useState('')
    const [eyeIcon, setEyeIcon] = useState(EyeSlashSVG)
    const [hoveringForgotPass, setHoveringForgotPass] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(() => {
        // Change this to select the desired default country
        const defaultCountry = countries.find(c => c.isoCode === 'US'); // Use 'US' for United States
        return defaultCountry ? `${defaultCountry.name} (${defaultCountry.dialCode})` : '';
    });
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [country, setCountry] = useState({
        ip: "",
        countryName: "",
        countryCode: "",
        city: "",
        timezone: ""
    });

    const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
    const [isPhoneNumberAvailable, setIsPhoneNumberAvailable] = useState(true);

    const [loginError, setLoginError] = useState(false);

    const getGeoInfo = () => {
        axios
            .get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                console.log("get ip data: ", data)
                setCountry({
                    ...country,
                    ip: data.ip,
                    countryName: data.country_name,
                    countryCode: data.country_calling_code,
                    city: data.city,
                    timezone: data.timezone
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        getGeoInfo()
    }, [])

    useEffect(() => {
        if (!country.ip) {
            return
        }

        const matchedCountry = countries.find(item => 
            item.name.toLowerCase().includes(country.countryName.toLowerCase())
        );

        if (matchedCountry) {
            setSelectedCountry(`${matchedCountry.name} (${matchedCountry.dialCode})`);
            setPrefix(matchedCountry.dialCode);
        }
    }, [country, countries])

    useEffect(() => {
        // This effect runs when isLogin prop changes (i.e., when switching between signup and login modes)
        resetErr();
        setLoginError(false);
        if (phoneNumber) {
            validatePhoneNumber(true);
        }
    }, [props.props.isLogin]);

    const resetErr = () => {
        setPassErr(false);
        setPhoneErr(false);
        setCountryCodeErr(false);
        setPhoneNumberError('');
        setPasswordError('');
    }

    const handleLoginResult = async (loginInfo) => {
        resetErr()
        setLoginError(false);

        let hasError = false;

        // Validate phone number
        if (!phoneNumber.trim()) {
            setPhoneErr(true);
            setPhoneNumberError('phoneRegistry.phoneRequired');
            hasError = true;
        } else if (!isValidPhoneNumber(prefix + phoneNumber)) {
            setPhoneErr(true);
            setPhoneNumberError('phoneRegistry.phoneInvalid');
            hasError = true;
        }

        // Validate password
        if (!password.trim()) {
            setPassErr(true);
            setPasswordError('phoneRegistry.passwordRequired');
            hasError = true;
        }

        // If there are any errors, return early
        if (hasError) {
            return;
        }

        if (props.props.isLogin) {
            // Login mode
            try {
                const response = await axios.post(API_URL + '/api/login', { 
                    phone: prefix + phoneNumber,
                    password: password
                });
                
                if (response.status === 200) {
                    // Login successful
                    const prop = props.props
                    const callback = prop.callback
                    callback({
                        type: "login",
                        result: "ok",
                        phone: prefix + phoneNumber,
                    })
                }
            } catch (error) {
                console.error("Login error:", error);
                setLoginError(true);
                setPhoneErr(true);
                setPassErr(true);
                setPasswordError("phoneRegistry.incorrectCredentials");
            }
        } else {
            // Signup mode
            try {
                const response = await axios.post(API_URL + '/api/send_totp', { 
                    phone: prefix + phoneNumber,
                    routeName: 'signup'
                });
                
                if (response.status === 200) {
                    // Phone number is available, proceed with signup
                    const prop = props.props
                    const callback = prop.callback
                    callback({
                        type: "phone",
                        result: "ok",
                        password,
                        invitationCode,
                        phone: prefix + phoneNumber,
                    })
                }
            } catch (error) {
                console.error("Error checking phone number:", error);
                setPhoneErr(true);
                let errMsg = error.response?.data?.error || "An error occurred while checking the phone number.";
                if (errMsg === "User already exists") {
                    errMsg = "phoneRegistry.phoneInUse";
                }
                setPhoneNumberError(errMsg);
            }
        }
    }

    const isValidPhoneNumber = (number) => {
        // This regex allows for a string of digits, optionally starting with a '+'
        const phoneRegex = /^\+?[0-9]{7,15}$/;
        return phoneRegex.test(number);
    }

    const checkPhoneNumberAvailability = useCallback(
        debounce(async (phoneNumber) => {
            if (!props.props.isLogin && phoneNumber && isValidPhoneNumber(prefix + phoneNumber)) {
                try {
                    const response = await axios.post(API_URL + '/api/send_totp', { 
                        phone: prefix + phoneNumber,
                        routeName: 'signup'
                    });
                    
                    if (response.status === 200) {
                        console.log("The verification code has been sent successfully");
                        setIsPhoneNumberAvailable(true);
                        setPhoneErr(false);
                        setPhoneNumberError("");
                    }
                } catch (error) {
                    console.error("Error checking phone number:", error);
                    setIsPhoneNumberAvailable(false);
                    setPhoneErr(true);
                    let errMsg = error.response?.data?.error || "An error occurred while checking the phone number.";
                    if (errMsg === "User already exists") {
                        errMsg = "phoneRegistry.phoneInUse";
                    }
                    setPhoneNumberError(errMsg);
                }
            }
        }, 300),
        [prefix, props.props.isLogin]
    );

    const validatePhoneNumber = (isModeSwitching = false) => {
        if (!phoneNumber.trim()) {
            setPhoneErr(true);
            setPhoneNumberError("phoneRegistry.phoneRequired");
            return false;
        } else if (!isValidPhoneNumber(prefix + phoneNumber)) {
            setPhoneErr(true);
            setPhoneNumberError("phoneRegistry.phoneInvalid");
            return false;
        }

        if (!props.props.isLogin) {
            // Check availability in signup mode, even when switching modes
            checkPhoneNumberAvailability(phoneNumber);
        } else {
            setPhoneErr(false);
            setPhoneNumberError('');
        }

        return true;
    }

    const clearLoginError = () => {
        if (props.props.isLogin && loginError) {
            setLoginError(false);
            setPhoneErr(false);
            setPassErr(false);
            setPhoneNumberError('');
            setPasswordError('');
        }
    };

    const inputStyles = {
        color: "#232323", // Changed from #847E7C to #232323
        fontWeight: 'medium', // Added medium font weight for input text
        _placeholder: { color: '#847E7C', fontWeight: 'medium', fontSize: '16px' },
        border: '2px solid #E4E6E6',
        height: "53px",
        _focus: { 
            borderColor: "#232323",
            color: "#232323"
        },
        fontSize: '16px',
    };

    return (
        <Flex direction="column" h="90%" overflow="auto" w="100%">
            <VStack spacing={4} align="stretch" flex={1}>
                <FormControl isInvalid={countryCodeErr}>
                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                        {t('phoneRegistry.countryCode')}
                    </Text>
                    <Box px="1px">
                        <Select
                            placeholder={selectedCountry}
                            onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                if (selectedIndex > 0 && selectedIndex <= countries.length) {
                                    const country = countries[selectedIndex - 1];
                                    setPrefix(country.dialCode);
                                    setSelectedCountry(`${country.name} (${country.dialCode})`);
                                } else {
                                    console.error("Invalid selection index:", selectedIndex);
                                }
                            }}
                            {...inputStyles}
                            borderColor="#E4E6E6"
                            _focus={{ 
                                borderColor: "#232323",
                                color: "#232323"
                            }}
                            _hover={ {bg: "#F7F7F7"} }
                            sx={{
                                '& option': {
                                    color: '#847E7C',
                                    fontWeight: 'medium',
                                    fontSize: '16px', // Add this line to set the font size for the options
                                },
                                '&:not(:placeholder-shown)': {
                                    color: '#232323',
                                    fontWeight: 'medium',
                                },
                            }}
                        >
                            {countries.map((item, index) => (
                                <option value={item.dialCode} key={index}>
                                    {`${item.name} (${item.dialCode})`}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    {countryCodeErr && <FormErrorMessage>{loginErr}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={phoneErr || loginError}>
                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                        {t('phoneRegistry.phoneNumber')}
                    </Text>
                    <Box px="1px">
                        <Input
                            type='tel'
                            placeholder={t('phoneRegistry.phoneNumberPlaceholder')}
                            value={phoneNumber}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                                if (loginError) {
                                    clearLoginError()
                                } else {
                                    setPhoneErr(false)
                                    setPhoneNumberError('')
                                }
                            }}
                            onFocus={() => {
                                setIsPhoneNumberFocused(true)
                                if (loginError) clearLoginError()
                            }}
                            onBlur={() => {
                                setIsPhoneNumberFocused(false);
                                validatePhoneNumber();
                            }}
                            {...inputStyles}
                            autoComplete="new-password"
                            borderColor={isPhoneNumberFocused ? "#232323" : (phoneErr || loginError ? "#C81E1E" : "#E4E6E6")}
                            _hover={ {bg: "#F7F7F7"} }
                            bg={(phoneErr || loginError) ? "#FDF2F2" : "white"}
                            _focus={{
                                borderColor: (phoneErr || loginError) ? "#C81E1E" : "#232323",
                                boxShadow: "none"
                            }}
                            sx={{
                                '&[aria-invalid=true]': {
                                    borderColor: '#C81E1E',
                                    boxShadow: 'none',
                                    _focus: {
                                        borderColor: '#C81E1E',
                                        boxShadow: 'none',
                                    }
                                }
                            }}
                        />
                        {phoneErr && !loginError && (
                            <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                {t(phoneNumberError)}
                            </FormErrorMessage>
                        )}
                    </Box>
                </FormControl>

                <FormControl isInvalid={passErr || loginError}>
                    <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                        {t('phoneRegistry.password')}
                    </Text>
                    <Box px="1px">
                        <InputGroup>
                            <Input
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                placeholder={t('phoneRegistry.passwordPlaceholder')}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    if (loginError) {
                                        clearLoginError()
                                    } else {
                                        setPassErr(false)
                                        setPasswordError('')
                                    }
                                }}
                                onFocus={() => {
                                    if (loginError) clearLoginError()
                                }}
                                onBlur={() => {
                                    if (!password.trim()) {
                                        setPassErr(true)
                                        setPasswordError('phoneRegistry.passwordRequired')
                                    }
                                }}
                                {...inputStyles}
                                autoComplete="new-password"
                                borderColor={(passErr || loginError) ? "#C81E1E" : "#E4E6E6"}
                                _hover={ {bg: "#F7F7F7"} }
                                bg={(passErr || loginError) ? "#FDF2F2" : "white"}
                                _focus={{
                                    borderColor: (passErr || loginError) ? "#C81E1E" : "#232323",
                                    boxShadow: "none"
                                }}
                                sx={{
                                    '&[aria-invalid=true]': {
                                        borderColor: '#C81E1E',
                                        boxShadow: 'none',
                                        _focus: {
                                            borderColor: '#C81E1E',
                                            boxShadow: 'none',
                                        }
                                    }
                                }}
                            />
                            <InputRightElement width='2.5rem' height="100%">
                                <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
                                    <Box 
                                        as="button" 
                                        onClick={() => setShow(!show)}
                                        aria-label={show ? "Hide password" : "Show password"}
                                    >
                                        {show ? <EyeOutlineSVG /> : <EyeSlashSVG />}
                                    </Box>
                                </Flex>
                            </InputRightElement>
                        </InputGroup>
                        {(passErr || loginError) && (
                            <FormErrorMessage fontSize="14px" fontWeight="medium" color="#C81E1E">
                                {t(passwordError || 'phoneRegistry.incorrectCredentials')}
                            </FormErrorMessage>
                        )}
                    </Box>
                </FormControl>

                {!props.props.isLogin && (
                    <FormControl>
                        <Text as="label" mt="3px" fontWeight="semibold" fontSize="14px" mb={2} display="block">
                            {t('phoneRegistry.invitationCode')}
                            <Box as="span" ml={1} color='#B0B0B0' fontSize={14} fontWeight={600}>
                                {t('phoneRegistry.optional')}
                            </Box>
                        </Text>
                        <Box px="1px">
                            <Input
                                type='string'
                                placeholder={t('phoneRegistry.invitationCodePlaceholder')}
                                value={invitationCode}
                                onChange={(e) => SetInvitationCode(e.target.value)}
                                {...inputStyles}
                                autoComplete="new-password"
                                borderColor="#E4E6E6"
                                _hover={ {bg: "#F7F7F7"} }
                            />
                        </Box>
                    </FormControl>
                )}

                {props.props.isLogin && (
                    <Link
                        onClick={() => {
                            const callback = props.props.callback
                            callback({
                                type: "resetPass",
                                from: "phone"
                            })
                        }}
                        color="#6062FF"
                        _hover={{ color: "#5145CD" }}
                        alignSelf="flex-start"
                        fontFamily="THICCCBOI, sans-serif"
                        mt="3px"
                        fontWeight="medium"
                    >
                        {t('phoneRegistry.forgotPassword')}
                    </Link>
                )}
                <Box mt={6}>
                    <SignupZone props={{
                        login: {
                            loginType: "phone",
                            prefix: prefix,
                            phoneNumber,
                            password,
                            invitationCode,
                            handleLoginResult,
                            isLogin: props.props.isLogin,
                        }
                    }} />
                </Box>
            </VStack>


        </Flex>
    )

}